<template>
  <div class="Friendship_I">
    <div class="headNavs">
      <div style="display:flex">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect_top">
          <el-menu-item v-for="item in SiteList" :key="item.site_id" :index="item.site_id" :value="item.site_id">{{item.name}}</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：包含各个站点工作服务网的链接、学习网站</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="Refresh()"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入名称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="site_id"
        header-cell-class-name="table-header"
        @selection-change="handleSelect">
        <el-table-column prop="id" label="ID" width="80"  align="center"></el-table-column>
        <el-table-column prop="name" label="名称" align="center"></el-table-column>
        <el-table-column prop="url" label="友情链接" width="500" align="center">
          <template #default="scope" >
             <div>
               <el-link class="tttyt" :href='scope.row.url' target="_blank"> <i class="el-icon-paperclip"></i>&nbsp;{{scope.row.url}}</el-link>
             </div>
          </template> 
        </el-table-column>
        <el-table-column prop="target_text" label="打开方式" align="center"></el-table-column>
        <el-table-column prop="status_text" label="状态" align="center"></el-table-column>
        <el-table-column prop="weigh" label="权重" width="80" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.$index,scope.row)"
              >编辑
              </el-button>
            </div>
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.$index, scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm">
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>站点</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.site_id" placeholder="请选择类型" >
                <el-option v-for="item  in SiteList" :key="item.site_id" :value="item.site_id" :label="item.name" ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>打开方式</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.target" placeholder="请选择类型"  >
                <el-option v-for="(item,key) in TypeList" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName" style="margin-bottom:9px"><span class="signSpan">* </span>状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.status" placeholder="请选择是否启用" >
                <el-option v-for="(item, key) in StatusList" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>名称</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.name" placeholder="请填写名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>友链地址</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.url" placeholder="请填写链接"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>权重</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.weigh" placeholder="请填写权重"></el-input>
            </div>
          </el-form-item>

          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">图片</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  ref="upload-image"
                  list-type="picture-card"
                  :action="upload"
                  :headers="heads"
                  :limit="1"
                  :file-list="fileList"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],//总列表
      // 打开方式
      TypeList: {
        _blank: "新页面打开",
        _self: "本页面打开"
      },
      StatusList: [
        "隐藏",
        "正常"
      ],
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      // 列表数据
      Row: {
        site_id: "",
        name: "",
        image: "",
        status: 1,
        url: "",
        target: "",
        weigh: "",
      },
      //搜索参数
      search: {
        keyword: "",
        site_id: "",
      },
      fileList:[],
      addPanel: false,
      editPanel: false,
      editId: 0,
      siteId:'',
      titleMath:'',
      activeIndex:''
    }
  },
  mounted() {
    var that = this
    that.loadTable();
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    axios.get('/admin/ajax/siteList', {   // 站点列表
    }).then(function (res) {
      if (res.data.code == 0) {
        alert('请求失败');
      } else {
        that.SiteList = res.data.data
        that.activeIndex = that.SiteList[0].site_id
      }
    })
  },
  methods: {  
    // 导航选择
    handleSelect_top (e) {
      var that = this
      var site_id = e
      that.activeIndex = e
      axios({
        method: 'post',
        url: '/admin/general/friendlink/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter:{
          site_id
         }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.search.site_id = e
      })
    },
    // 获取列表数据
    loadTable(e) {
      var that = this
      axios({
        method: 'post',
        url: '/admin/general/friendlink/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if(e==false){
        that.search.keyword = ''
      }
      that.loadTable();
    },
    
    // 创建打开
    showAdd() {
      var that = this
      that.addPanel = true;
      that.titleMath = '添加'
      that.Row = {
        site_id: "",
        name: "",
        image: "",
        status: '',
        url: "",
        target: "",
        weigh: 0,
      };
      that.fileList = [];
    },
    //新增
    BtnAdd() {
      var that = this
      if (that.addPanel) {
        axios({
          method: 'post',
          url: '/admin/general/friendlink/add',
          data: {
            row: that.Row
          },
        }).then(function (res) {
          console.log(res.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.addPanel = false
            that.loadTable();
          }
        })
      }
    },
    
    // 编辑保存
    BtnEdit() {
      var that = this
      if (that.editPanel) {
        axios({
          method: 'put',
          url: '/admin/general/friendlink/edit',
          data: {
            id: that.editId,
            row: that.Row
          },
        }).then(function (res) {
          console.log(res.data.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editPanel = false
            that.loadTable();
          }
        })
      }
    },
    //获取编辑内容
    HandleEdit(e, data) {
      var that = this
      that.editPanel = true;
      that.titleMath = '编辑'
      that.editId = data.id
      axios.get('/admin/general/friendlink/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row = {
            site_id: res.data.data.row.site_id,
            name: res.data.data.row.name,
            image: res.data.data.row.image,
            url: res.data.data.row.url,
            status: parseInt(res.data.data.row.status),
            target: res.data.data.row.target_text,
            weigh: res.data.data.row.weigh,
          }
          //图片
          let fileArr = [];
          let fileObj = {};
          fileObj.name = "file";
          fileObj.url = res.data.data.row.full_image;
          fileArr.push(fileObj);
          that.fileList = fileArr
        }
      })
    },

    // 关闭
    Close() {
      var that = this
      that.editPanel = false;
      that.addPanel = false;
    },
    //删除
    HandleDel(e, data) {
      var that = this
      var arr = []
      //主键ID
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        that.delTable(arr);
      })
    }, HandleDel(e, data) {
      var that = this
      var arr = []
      //主键ID
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        that.delTable(arr);
      })
    },
    delTable(arr) {
      var that = this
      axios({
        method: 'DELETE',
        url: '/admin/general/friendlink/del',
        data: {
          ids: arr
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
    },
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.Row.image = e.data.url
    },
    handleExceed  (files)  {
      this.$refs["upload-image"].clearFiles()
      this.$refs["upload-image"].handleStart(files[0])
      this.$refs["upload-image"].submit();
    },
    handlePreview(file) {
      console.log(file);
      window.open(file.url);
    },
    //勾选
    handleSelect(e) {
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;

      return y + "-" + MM + "-" + d
      
    },
  }
}
</script>

